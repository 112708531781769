html, body, #root {
  margin: 0;
  height: 100%;
}

/* Loading spinner */
:root {
  --sk-size: 56px;
  --sk-color: transparent;
}

/* recharts overrides */
.recharts-cartesian-axis-tick-line {
  stroke: none;
}
